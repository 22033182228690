import { change as changeFormValue, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import SeatMapContent from '../components/SeatMapContent';
import { getSelected, getPrice, processSeatSelection } from '../../utils/selection';
import { SEATING_PREFERENCE_VALUES } from '../../utils/constants';
const mapStateToProps = (state, _ref) => {
  let {
    form
  } = _ref;
  const getSelectedFunction = getSelected('seatMap', form)(state);
  const {
    price,
    markup
  } = getPrice('seatMap', form)(state);
  const selectedSeats = formValueSelector(form)(state, 'seatMap') || {};
  const shouldShowConfirmationModal = form === 'traveler-details';
  return {
    getSelected: activeSegment => Object.values(getSelectedFunction(activeSegment)),
    shouldShowConfirmationModal,
    totalMarkup: markup,
    totalPrice: price,
    selectedSeats
  };
};
const mapDispatchToProps = (dispatch, _ref2) => {
  let {
    form,
    isSeatingPage = false,
    moveToExtraProductsPage,
    travelers,
    setIsSeatMapModalOpen
  } = _ref2;
  return {
    onChange: (boundSegmentIndex, travelerId, carrier, column, row, seat, price, markup, seatPreference) => {
      const traveler = travelers.find(t => t.id === travelerId);
      const getSeatPreference = sp => sp && sp !== SEATING_PREFERENCE_VALUES.NO_OPTION ? {
        seatPreference: sp
      } : {};
      const segment = {
        ...traveler,
        carrier,
        column,
        price,
        row,
        value: seat,
        markup: markup !== null && markup !== void 0 ? markup : 0,
        id: travelerId,
        ...getSeatPreference(seatPreference)
      };
      dispatch(changeFormValue(form, `seatMap.${boundSegmentIndex}.traveler${travelerId}`, segment));
    },
    onRemove: (boundSegmentIndex, travelerId) => {
      dispatch(changeFormValue(form, `seatMap.${boundSegmentIndex}.traveler${travelerId}`, null));
    },
    onClose: seatSelection => () => {
      const selectedSeats = processSeatSelection(seatSelection);
      if (selectedSeats.length === 0) {
        dispatch(changeFormValue(form, 'seatMap.value.value', 'false'));
      }
      if (isSeatingPage) {
        moveToExtraProductsPage();
      } else {
        setIsSeatMapModalOpen(false);
      }
    },
    onOptOut() {
      dispatch(changeFormValue(form, 'seatMap.value.value', 'false'));
      if (isSeatingPage) {
        moveToExtraProductsPage();
      } else {
        setIsSeatMapModalOpen(false);
      }
    }
  };
};
const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onClose: dispatchProps.onClose(stateProps.selectedSeats)
});
export const SeatMapContentContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(SeatMapContent);
export default SeatMapContentContainer;