import { useEffect, useState } from 'react';
import { vars } from '@etg/wings';
import { css, cx } from '@eti/styles';
import type { ClassNames, DayClickEventHandler, DayModifiers } from 'react-day-picker';
import { DayPicker } from 'react-day-picker';
import { useDirection, useProperty, useSiteContext } from '@eti/providers';
import type { BestPricePerDay } from '@eti/schema-types';
import 'react-day-picker/dist/style.css';
import {
  calculateDayRange,
  getOneYearFromToday,
  isDisabled,
  isInRange,
  isSelected,
} from '../../utils/calendarDateUtils';
import type { Locale } from '../../utils/calendarLocales';
import { formatWeekdayName, getLocale } from '../../utils/calendarLocales';
import CustomDayWithPriceIndicator from './CustomDayWithPriceIndicator';
import CustomCaption from './CustomCaption';
import DaysHeader from './DaysHeader';

const calendarContainerStyle = css`
  margin-inline: -12px;
`;

const modifiersStyles = (isPriceCalendarEnabled: boolean) => ({
  disabled: {
    color: '#737373',
    cursor: 'default',
  },

  outside: {
    backgroundColor: '#fff',
    cursor: 'default',
  },

  inRange: {
    backgroundColor: `${vars.colors.inputs.tint}`,
    color: `${vars.colors.text}`,
  },

  selected: {
    backgroundColor: `${vars.colors.inputs.main}`,
    color: '#fff',
    borderRadius: isPriceCalendarEnabled ? '5px' : '3px',
    outlineOffset: '0',
  },

  today: {
    fontWeight: 100,
  },
});

const classNames: ClassNames = {
  head: css`
    margin-bottom: 4px;
  `,

  nav: css`
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: -4px;
    width: 100%;
  `,

  nav_icon: css`
    height: 14px;
    width: 14px;
  `,

  table: css`
    margin-block-start: 4px;
    width: 100%;
  `,
  month: css`
    padding-block-end: 16px;
  `,

  months: css`
    width: 100%;

    > div {
      margin: 0;
    }
  `,

  day: cx(
    'notranslate',
    css`
      && {
        color: ${vars.colors.text};
        font-size: 0.875rem;
        height: 100%;
        height: var(--rdp-cell-size);
        max-width: unset;
        -webkit-tap-highlight-color: transparent;
        width: 100%;

        &&:hover {
          background-color: ${vars.colors.inputs.hover};
        }

        &&[disabled] {
          opacity: unset;
        }

        &&[disabled]:hover {
          background-color: unset;
        }

        &&:focus,
        &&:focus-visible {
          background-color: unset;
          border: none;
          border-radius: 3px;
          outline: 2px solid ${vars.colors.focusRing};
          z-index: 999;
        }

        &&:active {
          background-color: #f0f0f0;
        }
      }
    `,
  ),
};

const priceCalendarClassNames: ClassNames = {
  ...classNames,
  root: css`
    margin: 16px 4px;
  `,

  nav: cx(
    classNames.nav,
    css`
      padding: 0 6px;
    `,
  ),

  table: cx(
    classNames.table,
    css`
      border-collapse: separate;
    `,
  ),

  day: cx(
    classNames.day,
    css`
      && {
        background-color: #fafafa;
        border: 2px solid #fff;
        border-radius: 5px;
        padding: 2px;

        &&[disabled]:hover {
          background-color: #fafafa;
        }

        &&:focus,
        &&:focus-visible {
          border: 2px solid ${vars.colors.inputs.focus};
          outline: none;
        }
      }
    `,
  ),
};

export interface MobileCalendarProps {
  bestPricesPerDay?: BestPricePerDay[];
  currentField?: string | null;
  daysFromTodayForValidDepartureDate?: number;
  languageCode?: string;
  loading?: boolean;
  onDepartureDateChange: (day: string) => void;
  onReturnDateChange: (day: string) => void;
  previousBoundDepartureDate?: Date;
  range?: { from?: Date; to?: Date };
  selectedTripType?: string;
}

const MobileCalendar = ({
  bestPricesPerDay,
  currentField,
  daysFromTodayForValidDepartureDate,
  loading = false,
  onDepartureDateChange,
  onReturnDateChange,
  previousBoundDepartureDate,
  range = {},
  selectedTripType,
}: MobileCalendarProps) => {
  const { direction } = useDirection();

  const { language } = useSiteContext();

  const { p } = useProperty();

  const isPriceCalendarEnabled = p('IbeClient.Price.Calendar.Enabled');

  const [locale, setLocale] = useState<Locale>();

  useEffect(() => {
    const loadLocal = async () => {
      if (language?.locale) {
        const tempLocale = await getLocale(language.locale);
        setLocale(tempLocale);
      }
    };
    loadLocal();
  }, [language?.locale]);

  const handleDayClick: DayClickEventHandler = (day, { disabled }) => {
    if (!disabled) {
      calculateDayRange(
        currentField,
        range.from,
        range.to,
        day,
        onDepartureDateChange,
        onReturnDateChange,
      );
    }
  };

  const modifiers: DayModifiers = {
    selected: (day) => isSelected(day, range.from, range.to),
    inRange: (day) => isInRange(day, range.from, range.to, {}),
    disabled: (day) =>
      isDisabled(
        day,
        daysFromTodayForValidDepartureDate,
        previousBoundDepartureDate,
        selectedTripType,
      ),
  };

  return (
    <div className={calendarContainerStyle}>
      <DaysHeader locale={locale} />

      <DayPicker
        classNames={isPriceCalendarEnabled ? priceCalendarClassNames : classNames}
        components={{
          Caption: (props) => <CustomCaption {...props} />,
          DayContent: isPriceCalendarEnabled
            ? (props) => (
                <CustomDayWithPriceIndicator
                  loading={loading}
                  priceCalendarData={bestPricesPerDay ?? undefined}
                  {...props}
                />
              )
            : undefined,
          HeadRow: () => null,
        }}
        dir={direction}
        disableNavigation
        formatters={{ formatWeekdayName }}
        locale={locale}
        modifiers={modifiers}
        modifiersStyles={modifiersStyles(isPriceCalendarEnabled)}
        numberOfMonths={13}
        onDayClick={handleDayClick}
        toMonth={getOneYearFromToday()}
      />
    </div>
  );
};

export default MobileCalendar;
