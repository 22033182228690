import { useState } from 'react';
import { price as priceUtil } from '@eti/utils';
import { useDidUpdateEffect } from '../../../custom-hooks/useDidUpdateEffect';
import { cleanText, getProductNameForTracking, getProductPrice, TRACKING_PROPERTIES, useProductSnowplowTracking } from '../../../custom-hooks/useProductSnowplowTracking';
const useBaggageTracker = (productName, numberOfTravelers, totalBaggageQuantity, totalPricePerBound, currentTravelerId, productPosition, shouldApplySelectionToAll, rootElement) => {
  const [selectedValue, setSelectedValue] = useState('false');
  const {
    sendAddToCartEvent,
    sendSelectEvent,
    updateContext
  } = useProductSnowplowTracking(productName, {
    rootElement,
    productPosition,
    sendSeenEvent: false
  });
  useDidUpdateEffect(async () => {
    const isFirstTraveler = Number(currentTravelerId) === 0;
    if (!isFirstTraveler || numberOfTravelers <= 1) {
      return;
    }
    if (isFirstTraveler && numberOfTravelers > 1) {
      if (selectedValue !== 'false') {
        await updateContext({
          price: priceUtil.formatPrice(totalPricePerBound),
          quantity: totalBaggageQuantity
        });
        sendAddToCartEvent({
          addToCart: shouldApplySelectionToAll,
          value: 'Same baggage option'
        });
      } else {
        await updateContext({
          price: '0',
          quantity: null
        });
        sendSelectEvent({
          isSelected: shouldApplySelectionToAll,
          label: `Same ${getProductNameForTracking(productName)} option`
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldApplySelectionToAll]);
  const getUpdatedQuantity = isSelected => {
    const isFirstTraveler = Number(currentTravelerId) === 0;
    let qty;
    if (isSelected) {
      if (shouldApplySelectionToAll && isFirstTraveler) {
        qty = numberOfTravelers;
      } else {
        const updatedQty = totalBaggageQuantity + 1 > numberOfTravelers ? numberOfTravelers : totalBaggageQuantity + 1;
        qty = selectedValue !== 'false' ? totalBaggageQuantity : updatedQty;
      }
    } else if (!shouldApplySelectionToAll && totalBaggageQuantity > 0) {
      qty = totalBaggageQuantity - 1;
    } else {
      qty = null;
    }
    return qty;
  };
  const getUpdatedPrice = selectionValue => {
    const isFirstTraveler = Number(currentTravelerId) === 0;
    const cleanTotalPrice = Number(cleanText(priceUtil.formatPrice(totalPricePerBound)));
    const currentPrice = selectionValue === 'combined' ? cleanText(getProductPrice(rootElement, true)) : cleanText(getProductPrice(rootElement));
    let price;
    if (selectionValue !== 'false') {
      var _getUpdatedQuantity;
      let previousPrice = 0; // previous price
      if (selectedValue === 'combined') {
        previousPrice = cleanText(getProductPrice(rootElement, true));
      } else if (selectedValue === 'true') {
        previousPrice = cleanText(getProductPrice(rootElement));
      }
      price = shouldApplySelectionToAll && isFirstTraveler ? Number(currentPrice) * ((_getUpdatedQuantity = getUpdatedQuantity(true)) !== null && _getUpdatedQuantity !== void 0 ? _getUpdatedQuantity : 1) : cleanTotalPrice + Number(currentPrice) - Number(previousPrice);
    } else {
      // previous price
      const previousPrice = selectedValue === 'combined' ? cleanText(getProductPrice(rootElement, true)) : cleanText(getProductPrice(rootElement));
      price = cleanTotalPrice > 0 && !shouldApplySelectionToAll ? cleanTotalPrice - Number(previousPrice) : 0;
    }
    return price;
  };
  const addToCartEventOnChange = async selectionValue => {
    const isSelected = selectionValue !== 'false';
    setSelectedValue(selectionValue);
    await updateContext({
      price: `${getUpdatedPrice(selectionValue)}`,
      quantity: getUpdatedQuantity(isSelected)
    });
    sendAddToCartEvent({
      addToCart: isSelected,
      ...(selectionValue === 'combined' && {
        value: 'Baggage x 2'
      })
    });
  };
  const addToCartModalEvent = async value => {
    setSelectedValue(value ? 'true' : 'false');
    await updateContext({
      price: `${value ? getUpdatedPrice(value ? 'true' : 'false') : cleanText(priceUtil.formatPrice(totalPricePerBound))}`,
      quantity: value ? getUpdatedQuantity(value) : totalBaggageQuantity
    });
    sendAddToCartEvent({
      addToCart: value,
      property: TRACKING_PROPERTIES.VALIDATION_POP_UP
    });
  };
  return {
    addToCartEventOnChange,
    addToCartModalEvent
  };
};
export default useBaggageTracker;