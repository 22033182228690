import type { SearchData } from '@eti/providers';
import { startOfTomorrow, addDays, startOfDay } from 'date-fns';
import { CabinClass, TripType } from '@eti/schema-types';
import { getAgesOfChildren, getAgesOfInfants } from '../../../utils/format';
import { CHILD_AGE_DEFAULT_VALUE, MAX_INFANT_AGE, MAX_PASSENGERS } from '../constants/passengers';
import { convertToLocalDateString } from '../../../pages/result/utils/dateUtils';

const getPassengersData = (travelers: SearchData['travelers']) => {
  const childrenAges = getAgesOfChildren(travelers?.childAges);
  const infantsAges = getAgesOfInfants(travelers?.childAges);

  return {
    adults: travelers?.adults ?? 1,
    childrenAges: [
      ...childrenAges,
      ...Array(MAX_PASSENGERS - childrenAges.length).fill(CHILD_AGE_DEFAULT_VALUE),
    ],
    children: childrenAges.length,
    infantsAges: [
      ...infantsAges,
      ...Array(MAX_PASSENGERS - infantsAges.length).fill(MAX_INFANT_AGE),
    ],
    infants: infantsAges.length,
  };
};

const getBoundsData = (routes: SearchData['routes'], tripType: SearchData['tripType']) => {
  if (!routes) {
    const departureDate = startOfTomorrow();
    return {
      singleBound: {
        departureDate,
        returnDate: addDays(departureDate, 6),
      },
      multiBound: [{ departureDate }, null],
    };
  }

  const bounds = routes.map((route) => ({
    origin: {
      iataCode: route.originCode,
      name: route.originName,
      city: route.originCity,
      cityCode: route.originCityCode,
      continent: route.originContinent,
      continentCode: route.originContinentCode,
      country: route.originCountry,
      countryCode: route.originCountryCode,
    },
    destination: {
      iataCode: route.destinationCode,
      name: route.destinationName,
      city: route.destinationCity,
      cityCode: route.destinationCityCode,
      continent: route.destinationContinent,
      continentCode: route.destinationContinentCode,
      country: route.destinationCountry,
      countryCode: route.destinationCountryCode,
    },
    departureDate: startOfDay(new Date(convertToLocalDateString(route.departureDate))),
    departureTimeOfDay: route.departureTimeOfDay,
  }));

  const lastBound = bounds[bounds.length - 1];

  return {
    singleBound: {
      ...bounds[0],
      returnDate:
        tripType === TripType.OneWay ? null : startOfDay(new Date(lastBound.departureDate)),
      returnTimeOfDay: tripType === TripType.OneWay ? null : lastBound.departureTimeOfDay,
    },
    multiBound: bounds,
  };
};

export const getSearchFormDataFrom = (currentSearchData: SearchData) => {
  const {
    cabinClass = CabinClass.Economy,
    carrierCodes,
    routes,
    shouldOnlyShowDirectFlights,
    travelers,
    tripType = TripType.Return,
    flyRome,
  } = currentSearchData;
  const passengers = getPassengersData(travelers);
  const { singleBound, multiBound } = getBoundsData(routes, tripType);

  return {
    cabinClass,
    carrierCodes,
    hasDirectFlight: shouldOnlyShowDirectFlights,
    multiBound,
    passengers,
    singleBound,
    tripType,
    flyRome,
  };
};
