import { Button, Dialog, Inline, Text } from '@etg/wings';
import { Illustration } from '@eti/components';
import { useSiteContext, useTranslation, useSnowplowTracking } from '@eti/providers';
import { css } from '@eti/styles';
import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import type { FetchResult } from '@apollo/client';
import type { CancelMakeOrderMutation } from '@eti/schema-types';
import { routes } from '../../../constants/routesAndApisConstants';

interface CFCTimeoutModalProps {
  cancelMakeOrderMutation: () => Promise<FetchResult<CancelMakeOrderMutation>>;
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
}

const inlineMarginStyles = css`
  margin-top: 24px;
`;

const CFCTimeoutModal = ({ cancelMakeOrderMutation, isOpen, setIsOpen }: CFCTimeoutModalProps) => {
  const { t } = useTranslation();
  const { brand } = useSiteContext();
  const history = useHistory();
  const { isSnowplowTrackingEnabled, sendTrackEvent } = useSnowplowTracking();

  const handleModalCloseButton = useCallback(async () => {
    if (isSnowplowTrackingEnabled) {
      sendTrackEvent({
        category: 'Iframe CFC',
        action: 'Click',
        label: 'Return to flight selection',
        property: 'Ryanair',
        value: 'Session expired',
      });
    }

    await cancelMakeOrderMutation()
      .then(() => history.push(routes.RESULT))
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });

    setIsOpen(false);
  }, [cancelMakeOrderMutation, setIsOpen, history, isSnowplowTrackingEnabled, sendTrackEvent]);

  return (
    <Dialog
      data-testid="cfc-timeout-modal"
      illustration={<Illustration brand={brand.code} id="time-expired" />}
      isOpen={isOpen}
      title={t('CarrierFlightConfirmation.Timeout.Modal.Title')}
    >
      <Text asHtml>{t('CarrierFlightConfirmation.Timeout.Modal.Description')}</Text>
      <Inline align="end" className={inlineMarginStyles}>
        <Button onClick={() => handleModalCloseButton()} variant="primary">
          {t('CarrierFlightConfirmation.Timeout.Modal.Button.Description')}
        </Button>
      </Inline>
    </Dialog>
  );
};

export default CFCTimeoutModal;
