import { useEffect, useState } from 'react';
import { breakpoints } from '@etg/wings';
import { css } from '@eti/styles';

const getBackgroundStyles = (images: string[]) => css`
  background: url('${images[0]}') no-repeat center;
  background-size: cover;

  @media (min-width: ${breakpoints._560}) {
    background-image: url('${images[1]}');
  }

  @media (min-width: ${breakpoints._1024}) {
    background-image: url('${images[2]}');
  }
`;

export const backgroundImageTypes = {
  PANEL: 'panel',
  LOCATION: 'panel/locations',
} as const;

type BackgroundImageTypes = (typeof backgroundImageTypes)[keyof typeof backgroundImageTypes];

const IMAGE_SIZES = ['800', '1200', '1600'] as const;
const LOCATION_FALLBACK_IDS = [
  'loc-lon',
  'loc-sto',
  'loc-ist',
  'loc-bkk',
  'loc-cph',
  'loc-par',
  'loc-lis',
  'loc-rom',
  'loc-prg',
  'loc-ath',
] as const;

const getFallbackImageId = () =>
  LOCATION_FALLBACK_IDS[Math.floor(Math.random() * LOCATION_FALLBACK_IDS.length)];

export const useBackgroundImage = (
  imageId?: string,
  type: BackgroundImageTypes = backgroundImageTypes.PANEL,
) => {
  const [images, setImages] = useState<string[] | null>(null);

  useEffect(() => {
    let isMounted = true;

    const fetchImages = async (arg: string) => {
      const imageReferences: string[] = await Promise.all(
        IMAGE_SIZES.map(async (size) => {
          return await import(`@eti/assets/images/${type}/${arg}_${size}.webp`);
        }),
      ).then((result) => result.map((i) => i.default));

      if (isMounted) {
        setImages(imageReferences);
      }
    };

    (async () => {
      if (imageId) {
        try {
          await fetchImages(imageId);
        } catch (err) {
          if (type === backgroundImageTypes.LOCATION) {
            try {
              const fallbackId = getFallbackImageId();

              await fetchImages(fallbackId);
            } catch (error) {
              console.warn(error); // eslint-disable-line no-console
            }
          } else {
            console.warn(err); // eslint-disable-line no-console
          }
        }
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [imageId, type]);

  return images ? { cssClass: getBackgroundStyles(images) } : {};
};
