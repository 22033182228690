import PropTypes from 'prop-types';
import { X } from '@phosphor-icons/react';
import { vars, Heading, Stack } from '@etg/wings';
import { useDirection, useProperty, useTranslation } from '@eti/providers';
import { Drawer } from '@eti/components';
import { css, cx } from '@eti/styles';
import { useLanguage } from '../../../common/localization/custom-hooks/language';
import LanguageSelectionDropdown from '../../../common/localization/components/LanguageSelectionDropdown';
import SettingsGraphqlContainer from '../../../common/settings/components/SettingsGraphqlContainer';

const drawerStyles = css`
  background: ${vars.colors.header.base};
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  color: ${vars.colors.header.content};

  @media print {
    box-shadow: none;
  }
`;

const drawerHeaderStyles = css`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 16px;
`;

const menuStyles = css`
  height: 100vh;
  overflow: scroll;
  padding: 16px 16px 100px;
`;

const menuItemStyles = css`
  border-bottom: 1px solid ${vars.colors.divider};
  padding-bottom: 24px;

  &:last-child {
    border-bottom: 0;
  }
`;

const linkStyles = css`
  color: ${vars.colors.header.content};
  text-decoration: none;
`;

const buttonStyles = css`
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  padding: 0;
`;

const toggleDrawer = (isMobileMenuOpen, toggleMobileMenu) => () => {
  if (isMobileMenuOpen) {
    toggleMobileMenu();
  }
};

const MobileMenu = ({ isMobileMenuOpen, menu, menuTitleText, toggleMobileMenu }) => {
  const { lessThanTwoAvailableLanguages } = useLanguage();
  const { isRTL } = useDirection();
  const { t } = useTranslation();
  const { p } = useProperty();
  const isSettingsEnabled =
    p('IbeClient.Settings.Selection.Country.Enabled') ||
    p('IbeClient.Settings.Selection.Language.Enabled') ||
    p('IbeClient.Settings.Selection.Currency.Enabled');
  const shouldShowMultiLanguage = p('Site.MultiLanguage.Enabled') && !lessThanTwoAvailableLanguages;

  return (
    <Drawer
      className={cx(drawerStyles, { 'mobile-menu-open': isMobileMenuOpen })}
      id="mobile menu"
      isOpen={isMobileMenuOpen}
      onClickOutside={toggleDrawer(isMobileMenuOpen, toggleMobileMenu)}
      shouldAlignRight={!isRTL}
      width="100%"
    >
      <div className={drawerHeaderStyles}>
        <Heading level={2}>{menuTitleText}</Heading>
        <button
          aria-label={t('SiteMenu.CloseMenu.Label')}
          className={buttonStyles}
          data-testid="close-menu-button"
          onClick={toggleMobileMenu}
          title={t('SiteMenu.CloseMenu.Label')}
          type="button"
        >
          <X color={vars.colors.header.content} size={22} weight="light" />
        </button>
      </div>
      <div className={menuStyles}>
        <Stack data-testid="menu" spacing={24}>
          {menu.map(({ id, name: subMenuTitle, subMenu }) => (
            <div className={menuItemStyles} key={id}>
              <Stack spacing={24}>
                <Heading level={3}>{subMenuTitle}</Heading>
                {subMenu.map(({ name, path, target }) => (
                  <a
                    className={linkStyles}
                    data-testid={`menu-link-${name}`}
                    href={path}
                    key={name}
                    target={target}
                  >
                    {name}
                  </a>
                ))}
              </Stack>
            </div>
          ))}
          {isSettingsEnabled ? (
            <SettingsGraphqlContainer componentType="Menu" />
          ) : (
            shouldShowMultiLanguage && <LanguageSelectionDropdown />
          )}
        </Stack>
      </div>
    </Drawer>
  );
};

MobileMenu.propTypes = {
  isMobileMenuOpen: PropTypes.bool,
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
      subMenu: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          path: PropTypes.string,
          target: PropTypes.string,
        }),
      ),
    }),
  ),
  menuTitleText: PropTypes.string,
  toggleMobileMenu: PropTypes.func,
};

export default MobileMenu;
