import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FieldArray, formValueSelector } from 'redux-form';
import { m as motion } from 'framer-motion';
import { LinkButton, Stack, breakpoints, Inline } from '@etg/wings';
import {
  ValidationProvider,
  useDateFormat,
  useProperty,
  useSearch,
  useTranslation,
} from '@eti/providers';
import { isRequired, isRequiredIf, isValidLocation } from '@eti/validation';
import { SelfServiceRebookingTraveler } from '@eti/types';
import { CaretDown } from '@phosphor-icons/react';
import RebookingBanner from '../../../common/components/RebookingBanner';
import { tripTypes } from '../../../constants/tripTypesConstants';
import { searchForm } from '../utils/searchForm';
import Dates from '../containers/DatesContainer';
import { useToggle } from '../../../common/custom-hooks/toggle';
import {
  RESULT_PAGE_VERSION_2,
  RESULT_PAGE_VERSION_3,
} from '../../../pages/result/constants/resultConstants';
import { useSearchRedirectConfig } from '../utils/searchRedirect';
import { handleOnSubmit } from '../../../utils/searchFormSubmit';
import ExtendedSearchFormFields from './ExtendedSearchFormFields';
import TripTypes from './TripTypes';
import Bound from './Bound';
import Bounds from './Bounds';
import DirectFlight from './DirectFlight';
import SubmitButton from './SubmitButton';
import StopoverSelect from './StopoverSelect';
import {
  FormWrapper,
  StyledStack,
  extendedSearchFormStyles,
  iconStyles,
  linkButtonStyles,
  motionVariantsExtendedOptions,
  motionVariantsForDatesAndPassengers,
} from './styles/searchFormStyles';
import PassengersAndCabinClass from './PassengersAndCabinClass';

const { DEPARTURE_DATE, DESTINATION, MULTI_BOUND, ORIGIN, RETURN_DATE, TRIP_TYPE, SINGLE_BOUND } =
  searchForm.formFieldNames;

const isRequiredRule = isRequired({ message: 'Field is required' });
const isRequiredIfReturnRule = isRequiredIf(
  (allValues) => allValues && allValues[TRIP_TYPE] === tripTypes.RETURN,
)(RETURN_DATE);
const isValidLocationRule = isValidLocation(ORIGIN, DESTINATION)();

const rules = {
  [DEPARTURE_DATE]: isRequiredRule,
  [RETURN_DATE]: isRequiredIfReturnRule,
  [ORIGIN]: isValidLocationRule,
  [DESTINATION]: isValidLocationRule,
};

const getFieldName = (fieldName, selectedTripType) =>
  selectedTripType !== tripTypes.MULTI_STOP && `${SINGLE_BOUND}.${fieldName}`;

const mapStateToProps = (state, { form }) => ({
  origin: formValueSelector(form)(state, 'singleBound.origin.name'),
  destination: formValueSelector(form)(state, 'singleBound.destination.name'),
  returnTimeOfDay: formValueSelector(form)(state, 'singleBound.returnTimeOfDay'),
  departureTimeOfDay: formValueSelector(form)(state, 'singleBound.departureTimeOfDay'),
  selectedCarriers: formValueSelector(form)(state, 'carrierCodes'),
});

const SearchForm = ({
  departureTimeOfDay,
  destination,
  form,
  handleSubmit,
  isStartPage,
  language,
  numberOfBounds,
  onSearch,
  origin,
  rebookingData,
  returnTimeOfDay,
  selectedCarriers,
  selectedTripType,
  shouldShowRebookingBanner,
  updateBackgroundImage,
}) => {
  const { t } = useTranslation();
  const { p } = useProperty();
  const { date } = useDateFormat();
  const { currentSearchData, setSearchData } = useSearch();
  const searchRedirectConfig = useSearchRedirectConfig();
  const travelerTexts = {
    adult: t('Adult'),
    adults: t('Adults'),
    child: t('Child'),
    children: t('Children'),
    infant: t('Infant'),
    infants: t('Infants'),
  };

  const [isDatePassengerFieldsOpen, setIsDatePassengerFieldsOpen] = useState(
    Boolean(origin && destination),
  );
  const { isOpen: isMoreOptionsOpen, handleToggle } = useToggle(
    Boolean(returnTimeOfDay || departureTimeOfDay || selectedCarriers),
  );
  const formConstraints = rebookingData?.formConstraints || null;
  const isExtendedSearchFormEnabled = p('SearchForm.ShowExtendedForm.Enabled');
  const isMultiStop = selectedTripType === tripTypes.MULTI_STOP;
  const searchFormStopoverEnabled = p('Searchform.Stopover.Enabled');
  const resultPageVersion = String(p('Result.Page.Version'));

  const isStopoverEnabled =
    searchFormStopoverEnabled &&
    (resultPageVersion === RESULT_PAGE_VERSION_2 || resultPageVersion === RESULT_PAGE_VERSION_3);

  useEffect(() => {
    if (origin && destination) {
      setIsDatePassengerFieldsOpen(true);
    }
  }, [origin, destination]);

  const getName = (fieldName) => getFieldName(fieldName, selectedTripType);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleSubmit(
      handleOnSubmit(
        onSearch,
        travelerTexts,
        currentSearchData,
        setSearchData,
        searchRedirectConfig,
      ),
    )();
  };

  return (
    <ValidationProvider rules={rules}>
      {rebookingData && shouldShowRebookingBanner && (
        <RebookingBanner rebookingData={rebookingData} shouldShowRebookingCriteria />
      )}
      <Stack spacing={24}>
        <TripTypes form={form} />
        <FormWrapper isMultiStop={isMultiStop} onSubmit={handleFormSubmit}>
          <StyledStack spacing={24}>
            {!isMultiStop ? (
              <>
                <Bound
                  dateFormat={date}
                  daysFromTodayForValidDepartureDate={0}
                  form={form}
                  formConstraints={formConstraints}
                  isDatePassengerFieldsOpen={isDatePassengerFieldsOpen}
                  languageCode={language}
                  selectedTripType={selectedTripType}
                  shouldOpenDatePassengerFields={setIsDatePassengerFieldsOpen}
                  updateBackgroundImage={updateBackgroundImage}
                />
                <motion.div
                  animate={isDatePassengerFieldsOpen || !isStartPage ? 'visible' : 'hidden'}
                  data-testid="date-time-animation-wrapper"
                  initial={false}
                  variants={motionVariantsForDatesAndPassengers}
                >
                  <Inline
                    collapseBelow={breakpoints._768}
                    spacing={{
                      [breakpoints._0]: 24,
                      [breakpoints._768]: 48,
                    }}
                  >
                    <Dates
                      dateFormat={date}
                      daysFromTodayForValidDepartureDate={0}
                      departureDateName={getName(DEPARTURE_DATE)}
                      form={form}
                      languageCode={language}
                      numberOfBounds={numberOfBounds}
                      selectedTripType={selectedTripType}
                    />
                    <PassengersAndCabinClass formConstraints={formConstraints} />
                  </Inline>
                  {isExtendedSearchFormEnabled && (
                    <div className={extendedSearchFormStyles}>
                      <Inline spacing={8}>
                        <LinkButton
                          className={linkButtonStyles}
                          data-testid="more-search-options-button"
                          onClick={handleToggle}
                        >
                          {t('Searchform.MoreSearchOptions.Link.Text')}
                          <CaretDown
                            className={iconStyles(isMoreOptionsOpen)}
                            size={20}
                            weight="light"
                          />
                        </LinkButton>
                      </Inline>
                      <motion.div
                        animate={isMoreOptionsOpen ? 'show' : 'hide'}
                        data-testid="more-options-animation-wrapper"
                        initial={false}
                        variants={motionVariantsExtendedOptions}
                      >
                        <Stack spacing={24}>
                          <ExtendedSearchFormFields
                            form={form}
                            selectedTripType={selectedTripType}
                          />
                          {!isStopoverEnabled && <DirectFlight />}
                        </Stack>
                      </motion.div>
                    </div>
                  )}
                </motion.div>
                {isStopoverEnabled && <StopoverSelect form={form} />}
              </>
            ) : (
              <>
                <FieldArray
                  component={Bounds}
                  name={MULTI_BOUND}
                  props={{
                    form,
                    languageCode: language,
                    dateFormat: date,
                    daysFromTodayForValidDepartureDate: 0,
                    selectedTripType,
                    numberOfBounds,
                  }}
                />
                <Inline
                  collapseBelow={breakpoints._768}
                  spacing={{
                    [breakpoints._0]: 24,
                    [breakpoints._768]: 48,
                  }}
                >
                  {!isStopoverEnabled && <DirectFlight />}
                  <PassengersAndCabinClass formConstraints={formConstraints} />
                  <SubmitButton isMultiStop={isMultiStop} />
                </Inline>
                {isStopoverEnabled && <StopoverSelect form={form} />}
              </>
            )}
          </StyledStack>
          {!isMultiStop && (
            <SubmitButton
              isDatePassengerFieldsOpen={isDatePassengerFieldsOpen}
              isExtendedSearchFormEnabled={isExtendedSearchFormEnabled}
            />
          )}
        </FormWrapper>
      </Stack>
    </ValidationProvider>
  );
};

SearchForm.propTypes = {
  departureTimeOfDay: PropTypes.string,
  destination: PropTypes.string,
  form: PropTypes.string,
  handleSubmit: PropTypes.func,
  isStartPage: PropTypes.bool,
  language: PropTypes.string,
  numberOfBounds: PropTypes.number,
  onSearch: PropTypes.func,
  origin: PropTypes.string,
  rebookingData: PropTypes.shape({
    criteria: PropTypes.arrayOf(PropTypes.string),
    formConstraints: PropTypes.shape({
      passengers: PropTypes.shape({
        isDisabled: PropTypes.bool,
        maxNumberOfTravelers: PropTypes.number,
      }),
    }),
    initialValues: PropTypes.shape({
      passengers: PropTypes.shape({
        adults: PropTypes.number,
        children: PropTypes.number,
        infants: PropTypes.number,
      }),
    }),
    travelers: PropTypes.arrayOf(SelfServiceRebookingTraveler),
  }),
  returnTimeOfDay: PropTypes.string,
  selectedCarriers: PropTypes.array,
  selectedTripType: PropTypes.string,
  shouldShowRebookingBanner: PropTypes.bool,
  updateBackgroundImage: PropTypes.func,
};

export default connect(mapStateToProps)(SearchForm);
