import { Button, Dialog, Input, Stack, vars } from '@etg/wings';
import { useTranslation } from '@eti/providers';
import type { BestPricePerDay } from '@eti/schema-types';
import { TripType } from '@eti/schema-types';
import { css, cx } from '@eti/styles';
import type { RefObject } from 'react';
import { DEPARTURE_DATE, RETURN_DATE, SINGLE_BOUND } from '../../constants/formFieldNames';
import { generateDurationText } from '../../utils/calendarDateUtils';
import { formatGetDate } from '../../utils/format';
import MobileCalendar from '../calendar/MobileCalendar';
import ConnectedDateInput from './ConnectedDateInput';
import { inputStyles } from './styles';

const buttonsDatesWrapperStyles = css`
  & > *:first-child .calendarTrigger {
    border-end-start-radius: 3px;
    border-start-start-radius: 3px;
  }

  & > *:last-child .calendarTrigger {
    border-end-end-radius: 3px;
    border-start-end-radius: 3px;
  }

  & > *:not(:last-child) .calendarTrigger {
    border-inline-end: none;
    padding-inline-end: 1px;
  }
`;

const buttonWrapperStyles = css`
  display: flex;
  position: relative;

  &::before {
    border-color: ${vars.colors.inputs.focus};
    border-radius: 6px;
    border-style: solid;
    border-width: 3px;
    content: '';
    height: calc(100% + 6px);
    left: -3px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: -3px;
    transition: opacity 0.2s ease-in-out;
    width: calc(100% + 6px);
  }

  &:focus-within {
    z-index: 1;
  }

  &:focus-within::before {
    opacity: 1;
  }
`;

const datesButtonStyles = css`
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-color: ${vars.colors.inputs.border};
  border-radius: 0;
  border-style: solid;
  border-width: 1px;
  color: inherit;
  display: flex;
  font-family: inherit;
  font-size: 1rem;
  height: 3rem;
  justify-content: space-between;
  outline: none;
  padding-inline: 12px 10px;
  text-align: start;
  width: 100%;

  &[disabled] {
    background-color: #f8f8f8;
    cursor: not-allowed;
  }

  &:focus {
    border-inline-end: 1px solid ${vars.colors.inputs.main};
    border-radius: 3px;
  }

  & *:not(:last-child) &:focus {
    padding-inline-end: 0;
  }
`;

const datesPlaceholderStyles = css`
  color: #737373;
`;

const dialogFooterDividerStyles = css`
  background-color: ${vars.colors.inputs.main};
  height: 1px;
  margin-inline: -16px;
  transform: translateY(-15px);
  width: calc(100% + 32px);
`;

const dialogStyles = css`
  & > * > :last-child {
    margin-top: auto;
  }
`;

const elevateFocusedInputStyles = css`
  &&:focus-within {
    z-index: 10;
  }
`;

interface MobileDatesProps {
  currentField?: string | null;
  dateFormat?: string;
  daysFromTodayForValidDepartureDate?: number;
  departureDateName: string;
  departureInputRef: RefObject<HTMLInputElement>;
  handleInputFocus: (field: typeof DEPARTURE_DATE | typeof RETURN_DATE) => any;
  hasReturnDate: boolean;
  hideCalendar: () => void;
  isCalendarVisible: boolean;
  loading?: boolean;
  mobileCalendarDialogRef: RefObject<HTMLDivElement>;
  onDepartureDateChange: (day: string) => void;
  onReturnDateChange: (day: string) => void;
  previousBoundDepartureDate?: Date;
  priceData?: BestPricePerDay[];
  range: { from?: Date; to?: Date };
  returnInputRef: RefObject<HTMLInputElement>;
  selectedTripType?: TripType;
}

const DatesWithCalendarDialog = ({
  currentField,
  dateFormat,
  daysFromTodayForValidDepartureDate,
  departureDateName,
  departureInputRef,
  handleInputFocus,
  hasReturnDate,
  hideCalendar,
  isCalendarVisible,
  loading,
  mobileCalendarDialogRef,
  onDepartureDateChange,
  onReturnDateChange,
  previousBoundDepartureDate,
  priceData,
  range,
  returnInputRef,
  selectedTripType,
}: MobileDatesProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Stack spacing={4}>
        <Input.Group className={buttonsDatesWrapperStyles}>
          <Stack spacing={4} style={{ width: '100%' }}>
            <Input.Label htmlFor={departureDateName}>
              {t('Searchform.DepartureDate.Label')}
            </Input.Label>
            <div className={buttonWrapperStyles}>
              <button
                className={cx(datesButtonStyles, 'calendarTrigger')}
                onClick={handleInputFocus(DEPARTURE_DATE)}
                type="button"
              >
                {range.from ? (
                  formatGetDate(dateFormat)(range.from)
                ) : (
                  <span className={datesPlaceholderStyles}>{t('General.Departure')}</span>
                )}
              </button>
            </div>
          </Stack>

          {selectedTripType !== TripType.MultiStop && selectedTripType !== TripType.OneWay && (
            <Stack spacing={4} style={{ width: '100%' }}>
              <Input.Label htmlFor={`${SINGLE_BOUND}.${RETURN_DATE}`}>
                {t('Searchform.ReturnDate.Label')}
              </Input.Label>
              <div className={buttonWrapperStyles}>
                <button
                  className={cx(datesButtonStyles, 'calendarTrigger')}
                  onClick={handleInputFocus(RETURN_DATE)}
                  type="button"
                >
                  {range.to ? (
                    formatGetDate(dateFormat)(range.to)
                  ) : (
                    <span className={datesPlaceholderStyles}>{t('General.Return')}</span>
                  )}
                </button>
              </div>
            </Stack>
          )}
        </Input.Group>
      </Stack>

      <Dialog
        className={dialogStyles}
        footer={
          <>
            <div className={dialogFooterDividerStyles} />
            <Stack>
              <Stack spacing={4}>
                <Input.Group>
                  <Stack spacing={4} style={{ width: '100%' }}>
                    <Input.Label htmlFor={departureDateName}>
                      {t('Searchform.DepartureDate.Label')}
                    </Input.Label>
                    <ConnectedDateInput
                      className={cx(inputStyles, {
                        [elevateFocusedInputStyles]: currentField === DEPARTURE_DATE,
                      })}
                      dateFormat={dateFormat}
                      inputRef={departureInputRef}
                      name={departureDateName}
                      placeholder={t('General.Departure')}
                      validationRuleName={DEPARTURE_DATE}
                    />
                  </Stack>

                  {hasReturnDate && (
                    <Stack spacing={4} style={{ width: '100%' }}>
                      <Input.Label htmlFor={`${SINGLE_BOUND}.${RETURN_DATE}`}>
                        {t('Searchform.ReturnDate.Label')}
                      </Input.Label>
                      <ConnectedDateInput
                        className={cx(inputStyles, {
                          [elevateFocusedInputStyles]: currentField === RETURN_DATE,
                        })}
                        dateFormat={dateFormat}
                        inputRef={returnInputRef}
                        name={`${SINGLE_BOUND}.${RETURN_DATE}`}
                        placeholder={t('General.Return')}
                        validationRuleName={RETURN_DATE}
                      />
                    </Stack>
                  )}
                </Input.Group>
              </Stack>
              <Button data-testid="passengers-confirm" onClick={hideCalendar} variant="primary">
                {generateDurationText(
                  range.from,
                  range.to,
                  t('Searchform.Calendar.Confirm.Singular'),
                  t('Searchform.Calendar.Confirm.Plural'),
                )}
              </Button>
            </Stack>
          </>
        }
        isOpen={isCalendarVisible}
        onDismiss={hideCalendar}
        title={t('Searchform.Calendar.DialogTitle.Text')}
      >
        <div ref={mobileCalendarDialogRef}>
          <MobileCalendar
            bestPricesPerDay={priceData}
            currentField={currentField}
            daysFromTodayForValidDepartureDate={daysFromTodayForValidDepartureDate}
            loading={loading}
            onDepartureDateChange={onDepartureDateChange}
            onReturnDateChange={onReturnDateChange}
            previousBoundDepartureDate={previousBoundDepartureDate}
            range={range}
            selectedTripType={selectedTripType}
          />
        </div>
      </Dialog>
    </>
  );
};
export default DatesWithCalendarDialog;
