import { ContentWrapper } from '@eti/components';
import { Columns, Column, breakpoints, Inline } from '@etg/wings';
import { css } from '@eti/styles';
import Media from 'react-media';
import { useAuth } from '@eti/authentication';
import { forwardRef } from 'react';
import TripDetailsTitle from '../../pages/result/components/v3/TripDetailsTitle/TripDetailsTitle';
import MyBookingsButton from '../../common/authentication/components/MyBookingsButton';
import LanguageSelectionButton from '../../common/localization/components/LanguageSelectionButton';
import SettingsGraphqlContainer from '../../common/settings/components/SettingsGraphqlContainer';
import SupportMenu from './site_menu/SupportMenu';
import SiteLogo from './SiteLogo';

const contentWrapperStyles = css`
  align-items: center;
  display: flex;
  padding: 8px;

  @media (min-width: ${breakpoints._768}) {
    padding: 16px 15px;
  }
`;

const columnsWrapperStyles = css`
  align-items: center;
`;

interface SiteHeaderV2Props {
  baseUrl: string;
  brand: string;
  isSettingsEnabled?: boolean;
  shouldShowMultiLanguage: boolean;
  shouldShowSupportMenu: boolean;
  siteName: string;
  supportMenu: any;
}

export const SiteHeaderV2 = forwardRef<HTMLElement, SiteHeaderV2Props>(
  (
    {
      siteName,
      baseUrl,
      brand,
      shouldShowSupportMenu,
      shouldShowMultiLanguage,
      supportMenu,
      isSettingsEnabled = false,
    },
    ref,
  ) => {
    const { user } = useAuth();

    return (
      <ContentWrapper className={contentWrapperStyles}>
        <Columns
          className={columnsWrapperStyles}
          spacing={{
            [breakpoints._0]: 16,
            [breakpoints._970]: 24,
          }}
        >
          <Column data-testid="siteHeader" width={{ [breakpoints._0]: '3/12' }}>
            <SiteLogo baseUrl={baseUrl} brand={brand} ref={ref} siteName={siteName} />
          </Column>

          <Column
            data-testid="trip-details-search-bar"
            width={{ [breakpoints._0]: '9/12', [breakpoints._768]: '8/12' }}
          >
            <TripDetailsTitle />
          </Column>
          <Media query={`(min-width: ${breakpoints._768})`}>
            {(matches) =>
              matches && (
                <Column
                  data-testid="language-and-bookings-button-container"
                  width={{ [breakpoints._768]: '1/12' }}
                >
                  <Inline align="end" alignY="center" noWrap spacing={8}>
                    {isSettingsEnabled ? (
                      <SettingsGraphqlContainer componentType="Header" iconOnly />
                    ) : (
                      <>
                        {shouldShowMultiLanguage && !isSettingsEnabled && (
                          <LanguageSelectionButton />
                        )}
                      </>
                    )}
                    {shouldShowSupportMenu && <SupportMenu menu={supportMenu} />}
                    {user && <MyBookingsButton iconOnly />}
                  </Inline>
                </Column>
              )
            }
          </Media>
        </Columns>
      </ContentWrapper>
    );
  },
);
