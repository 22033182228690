import { createPortal } from 'react-dom';
import { useEffect, useReducer, useRef, useState } from 'react';
import { analytics, isPartner } from '@eti/utils';
import PropTypes from 'prop-types';
import { css, cx, mediaQueries } from '@eti/styles';
import { variants } from '@eti/components';
import { FooterIcon as FooterIconType, SiteContext as SiteContextType } from '@eti/types';
import {
  SiteContextProvider,
  SnowplowTrackingProvider,
  useDirection,
  useProperty,
  useTranslation,
} from '@eti/providers';
import { CookieBanner } from '../../widgets/cookie-banner';
import getBrandCode from '../../utils/brandCode';
import { isDev } from '../../common/debug/utils';
import { routes } from '../../constants/routesAndApisConstants';
import HeaderTakeOverBanner from '../../common/components/HeaderTakeOverBanner';
import { SNOWPLOW_COLLECTOR_TEST_ENDPOINT, TEST_SERVER_URI } from '../constants/snowplow';
import Content from './Routes';
import TopHeaderNotification from './TopHeaderNotification';
import Footer from './Footer';
import SiteHeader from './SiteHeader';
import useCookieBannerFocus from './hooks/useCookieBannerFocus';

const wrapperStyles = css`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
`;

const siteHeaderWrapperStyles = (hasHeaderTakeOverBanner) => css`
  position: ${hasHeaderTakeOverBanner ? 'sticky' : 'fixed'};
  top: 0;
  width: 100%;
  z-index: 12;

  @media print {
    position: relative;
  }
`;

const mainContentWrapperStyles = (
  hasHeaderTakeOverBanner,
  hasHeaderAndFooter,
  hasActiveNotification,
  mainContainerMarginTop,
) => css`
  display: flex;
  flex: 1 1 auto; /* For the sticky footer. */
  ${!hasHeaderTakeOverBanner &&
  (hasHeaderAndFooter || hasActiveNotification) &&
  `margin-top: ${mainContainerMarginTop || '50'}px`};

  @media ${mediaQueries.large.up} {
    height: auto;
    ${!hasHeaderTakeOverBanner &&
    (hasHeaderAndFooter || hasActiveNotification) &&
    `margin-top: ${mainContainerMarginTop || '74'}px`};
  }

  @media print {
    margin-top: 0;
  }
`;

const mainClass = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export function menuReducer(shouldShowMenu) {
  const noMenuRoutes = new Set([
    routes.RESULT,
    routes.TRAVELER_DETAILS,
    routes.YOUR_SELECTED_TRIP,
    routes.EXTRA_PRODUCTS,
    routes.SEATING,
    routes.PAYMENT,
    routes.ORDER,
    routes.ORDER_STATUS,
    routes.ORDER_LIST,
    routes.CHANGE_TRIP,
  ]);
  const noMenuAndStepIndicatorRoutes = new Set([
    routes.MOBILE_TRAVEL_PLAN,
    `${routes.MOBILE_TRAVEL_PLAN}/`,
    routes.MOBILE_TRAVEL_PLAN_LOGIN,
    routes.MOBILE_TRAVEL_PLAN_FLIGHT_STATUS,
  ]);
  const orderDetailsRoutes = new Set([
    routes.ORDER_DETAILS,
    routes.ORDER_DETAILS_MANAGE_TRIP,
    routes.ORDER_DETAILS_CHECK_IN,
    routes.ORDER_DETAILS_TRAVEL_INFORMATION,
    routes.ORDER_DETAILS_EXTRA_PRODUCTS,
    routes.ORDER_DETAILS_CONTACT_US,
  ]);
  const isInGroup = (route, group) => group.has(route);

  return (_state, action) => {
    if (isInGroup(action.type, orderDetailsRoutes) || isInGroup(action.type, noMenuRoutes)) {
      return {
        shouldShowMenu: false,
      };
    }

    if (isInGroup(action.type, noMenuAndStepIndicatorRoutes)) {
      return {
        shouldShowMenu: false,
        shouldShowStepIndicator: false,
      };
    }

    return {
      shouldShowMenu,
    };
  };
}

const routesWithoutHeaderAndFooter = [
  routes.NEW_BRAND_REDIRECTION,
  routes.CFC_PAGE_STEP_TWO,
  routes.RYANAIR_ORDER_AUTHENTICATION,
];

const Main = ({ localization, siteContext, siteInformation }) => {
  const { header, footer } = siteInformation;
  const { brand, partner, productionWeb, siteName } = siteContext;
  const { p } = useProperty();
  const { t } = useTranslation();
  const { direction } = useDirection();
  const [headerHeight, setHeaderHeight] = useState(null);
  const [notificationHeight, setNotificationHeight] = useState(0);
  const [containerToLoad, setContainerToLoad] = useState(null);
  const [menuState, setIsMenuVisible] = useReducer(menuReducer(p('IbeClient.TopMenu.Enabled')), {});
  const isSnowplowTrackingEnabled = p('IbeClient.SnowplowTracking.Enabled');

  useEffect(() => {
    if (isSnowplowTrackingEnabled) {
      const collectorEndpointByEnvironment = window.location.href.includes(TEST_SERVER_URI)
        ? SNOWPLOW_COLLECTOR_TEST_ENDPOINT
        : productionWeb;
      const collectorEndpoint = isDev
        ? 'localhost:3000/snowplow-dummy-endpoint'
        : collectorEndpointByEnvironment;

      window.snowplow('newTracker', 'sp', collectorEndpoint, {
        appId: `sp-${siteName}`,
        discoverRootDomain: true,
        platform: 'web',
        cookieSameSite: 'Lax',
        sessionCookieTimeout: 3600,
        contexts: {
          webPage: true,
          performanceTiming: true,
        },
      });
    }
  }, [isSnowplowTrackingEnabled, productionWeb, siteName]);

  useEffect(() => {
    if (siteContext) {
      analytics.pushToDataLayer({
        event: 'siteContextEvent',
        ...analytics.getSiteInitialData(
          siteContext,
          p('StoreSessionTrackingEntryInDataLayer.Enabled'),
        ),
      });
    }
  }, [siteContext, p]);

  useEffect(() => {
    setContainerToLoad(document.getElementById('skip-content'));
  }, []);

  // TODO remove this once data is normalize:https://jira.etraveli.net/browse/WEB-1981
  const brandCode = getBrandCode({ brand, partner });

  const { current: timeFormat } = useRef({
    hour: p('TimeFormat.Hour'),
    minute: p('TimeFormat.Minute'),
    second: p('TimeFormat.Second'),
  });

  const headerAndInfoHeight = headerHeight + notificationHeight;

  const siteContextValue = {
    timeFormat,
    headerAndInfoHeight,
    ...siteContext,
    ...(isPartner(brandCode) && { brand: { name: brandCode, code: brandCode } }),
    ...localization,
  };

  const [isHeaderAndFooterVisible, isCookieConsentBoxEnabled, isExternalCookieConsentEnabled] = [
    !p('IbeClient.MenuAndFooter.Hide'),
    p('ShowCookieConsentBox.Enabled'),
    p('isExternalCookieConsentEnabled'),
  ];

  const headerAndFooterProps = isHeaderAndFooterVisible
    ? {
        siteHeader: {
          ...header,
          menuTitleText: t('MenuTitle'),
          direction,
          siteName,
          menuState,
          isLogoUrlRemoved: p('IbeClient.RemoveLogoUrl.Enabled'),
        },
        siteFooter: { ...footer, shouldShowSiteFlagIcons: p('Footer.ShowFlagIcons.Enabled') },
      }
    : {};

  const topHeaderNotificationVariant = p('IbeClient.TopHeaderMessage.Theme.Enabled')
    ? variants.GAIN_TRUST
    : variants.WARNING;

  const topHeaderNotificationProperties = {
    closeButtonLabel: t('Close'),
    contentText: t('IbeClient.UrgentBox.Content.Text'),
    headerText: t('IbeClient.UrgentBox.Header.Text'),
    isEnabled: p('IbeClient.TopHeaderMessage.Enable'),
    variant: topHeaderNotificationVariant,
  };

  const hasHeaderTakeOverBanner =
    p('StartPage.HeaderTakeOver.Enabled') &&
    p('StartPage.HeaderTakeOver.Position') === 'above' &&
    window.location.pathname.includes(routes.START);

  const hasHeaderAndFooter =
    Boolean(headerAndFooterProps?.siteHeader && headerAndFooterProps?.siteFooter) &&
    !routesWithoutHeaderAndFooter.some((route) => window.location.pathname.includes(route));

  const mainContainerMarginTop = headerHeight + notificationHeight;
  const hasActiveNotification = notificationHeight > 0;
  const isHeaderNotificationVisible =
    topHeaderNotificationProperties.isEnabled &&
    !sessionStorage.getItem('hide-top-header-notification');
  const { basePath = '', routes: pageRoutes = {} } = window?.spa_variables ?? {};
  const logoUrl = p('IbeClient.Header.ExternalLogoUrl');
  const defaultHomeUrl = `${basePath !== '/' ? basePath : ''}${pageRoutes['rf.start.action']}`;
  // eslint-disable-next-line no-extra-boolean-cast
  const urlToStartPage = Boolean(logoUrl) ? logoUrl : defaultHomeUrl;

  useCookieBannerFocus(
    isCookieConsentBoxEnabled && !isExternalCookieConsentEnabled
      ? 'etgCookieBanner'
      : 'onetrust-banner-sdk',
  );

  return (
    <SiteContextProvider {...siteContextValue}>
      <SnowplowTrackingProvider isEnabled={isSnowplowTrackingEnabled}>
        <div className={cx(wrapperStyles, 'etiMainContent_Container')} data-testid="mainContent">
          {hasHeaderTakeOverBanner && <HeaderTakeOverBanner />}
          <div className={siteHeaderWrapperStyles(hasHeaderTakeOverBanner)}>
            {topHeaderNotificationProperties.isEnabled && (
              <TopHeaderNotification
                closeButtonLabel={topHeaderNotificationProperties.closeButtonLabel}
                content={topHeaderNotificationProperties.contentText}
                id="top-header-notification"
                setNotificationHeight={setNotificationHeight}
                title={topHeaderNotificationProperties.headerText}
                variant={topHeaderNotificationProperties.variant}
              />
            )}
            {hasHeaderAndFooter && (
              <SiteHeader
                baseUrl={urlToStartPage}
                {...headerAndFooterProps?.siteHeader}
                hasHeaderTakeOverBanner={hasHeaderTakeOverBanner}
                headerAndInfoHeight={headerAndInfoHeight}
                isHeaderNotificationVisible={isHeaderNotificationVisible}
                notificationHeight={notificationHeight}
                setHeaderHeight={setHeaderHeight}
              />
            )}
          </div>
          <div
            className={cx(
              mainContentWrapperStyles(
                hasHeaderTakeOverBanner,
                hasHeaderAndFooter,
                hasActiveNotification,
                mainContainerMarginTop,
              ),
              'etiMainContent',
            )}
          >
            {isCookieConsentBoxEnabled && !isExternalCookieConsentEnabled && (
              <CookieBanner dataTestId="cookieBanner-widget" id="etgCookieBanner" />
            )}
            <main className={mainClass} id="main" role="main">
              <Content
                headerAndInfoHeight={headerAndInfoHeight}
                setIsMenuVisible={setIsMenuVisible}
              />
            </main>
            {containerToLoad &&
              createPortal(
                <a className="skip-to-main-content-link" href="#main">
                  {t('Accessibility.SkipToMainContent.Text')}
                </a>,
                containerToLoad,
              )}
          </div>
          {!p('CustomerWeb.HideFooter.Enabled') && hasHeaderAndFooter && (
            <Footer brand={brandCode} {...headerAndFooterProps?.siteFooter} />
          )}
        </div>
      </SnowplowTrackingProvider>
    </SiteContextProvider>
  );
};

Main.propTypes = {
  localization: PropTypes.shape({
    availableLanguages: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        id: PropTypes.number,
        locale: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
  }),
  siteContext: SiteContextType,
  siteInformation: PropTypes.shape({
    footer: PropTypes.shape({
      legal: PropTypes.string,
      paymentProviders: PropTypes.arrayOf(FooterIconType),
      siteFlags: PropTypes.arrayOf(FooterIconType),
      trustedPartners: PropTypes.arrayOf(FooterIconType),
    }),
    header: PropTypes.shape({
      component: PropTypes.shape({
        Component: PropTypes.func,
        props: PropTypes.objectOf(PropTypes.any),
      }),
      mainMenuItems: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          path: PropTypes.string,
          subMenu: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string,
              path: PropTypes.string,
              target: PropTypes.string,
            }),
          ),
        }),
      ),
    }),
  }),
};

export default Main;
