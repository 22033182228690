import { breakpoints } from '@etg/wings';
import { useTranslation } from '@eti/providers';
import { ArrowsLeftRight } from '@phosphor-icons/react';
import type { ComponentPropsWithoutRef } from 'react';
import { css, cx, resetButtonStyle } from '@eti/styles';

const styles = css`
  ${resetButtonStyle};
  display: none;

  @media (min-width: ${breakpoints._768}) {
    display: block;

    &:focus-visible {
      outline: auto;
    }
  }
`;

interface SwitchDirectionButtonProps extends ComponentPropsWithoutRef<'button'> {
  'data-testid'?: string;
  rotate?: number;
}

const SwitchDirectionButton = ({
  className,
  'data-testid': dataTestId,
  onClick,
  rotate,
  ...props
}: SwitchDirectionButtonProps) => {
  const { t } = useTranslation();

  return (
    <button
      aria-label={t('Air.Search.SwitchLocations.Label')}
      className={cx(styles, className)}
      data-testid={dataTestId}
      onClick={onClick}
      title={t('Air.Search.SwitchLocations.Label')}
      type="button"
      {...props}
    >
      <ArrowsLeftRight
        size="24"
        weight="light"
        {...(rotate && { style: { transform: `rotate(${rotate}deg)` } })}
      />
    </button>
  );
};

export default SwitchDirectionButton;
