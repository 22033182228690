import { connect } from 'react-redux';
import { formValueSelector, change as changeFormValue } from 'redux-form';
import { constants } from '@eti/utils';
import { CABIN_BAGGAGE } from '../../../products';
import CabinBaggagePrimaryFlow from '../components/CabinBaggagePrimaryFlow';
import { getPrice } from '../../common/utils/priceUtil';
import { getNumberOfUnits } from '../../common/utils/units';
const productName = CABIN_BAGGAGE.name;
export const setTravelerChoice = _ref => {
  var _order$sellSpecificat, _order$sellSpecificat2, _price$markup$value, _price$markup, _price$price$value, _price$price;
  let {
    checkboxValue,
    form,
    dispatch,
    order,
    travelerId
  } = _ref;
  const baggageSpecification = ((_order$sellSpecificat = order.sellSpecification) === null || _order$sellSpecificat === void 0 ? void 0 : _order$sellSpecificat.sellPriceTravelers) || [];
  const {
    weight,
    weightUnit
  } = (_order$sellSpecificat2 = order.sellSpecification) !== null && _order$sellSpecificat2 !== void 0 ? _order$sellSpecificat2 : {};
  const {
    price,
    ancillaryCarrierPriceBreakdowns = []
  } = baggageSpecification.find(currTraveler => (currTraveler === null || currTraveler === void 0 ? void 0 : currTraveler.travelerId) === travelerId) || {};
  dispatch(changeFormValue(form, `${productName}.${productName}-traveler-${travelerId}`, {
    value: checkboxValue,
    id: travelerId,
    ...(checkboxValue === 'true' && {
      ...(ancillaryCarrierPriceBreakdowns && ancillaryCarrierPriceBreakdowns.length > 0 && {
        ancillaryCarrierPriceBreakdowns
      }),
      markup: (_price$markup$value = price === null || price === void 0 || (_price$markup = price.markup) === null || _price$markup === void 0 ? void 0 : _price$markup.value) !== null && _price$markup$value !== void 0 ? _price$markup$value : null,
      priceRaw: (_price$price$value = price === null || price === void 0 || (_price$price = price.price) === null || _price$price === void 0 ? void 0 : _price$price.value) !== null && _price$price$value !== void 0 ? _price$price$value : null,
      weight: weight !== null && weight !== void 0 ? weight : null,
      weightUnit: weightUnit !== null && weightUnit !== void 0 ? weightUnit : null
    })
  }));
};
export const onChangeAll = (dispatch, _ref2) => {
  let {
    form,
    order
  } = _ref2;
  return (checkboxValue, firstTravelerSelectionValue) => {
    dispatch(changeFormValue(form, `${productName}.shouldApplySelectionToAll`, checkboxValue));
    const selectedValue = checkboxValue ? firstTravelerSelectionValue : '';
    order.travelers.slice(1).forEach(traveler => {
      if (traveler.ageType !== constants.ageTypes.INFANT) {
        setTravelerChoice({
          form,
          checkboxValue: selectedValue || '',
          dispatch,
          order,
          travelerId: (traveler === null || traveler === void 0 ? void 0 : traveler.id) || ''
        });
      }
    });
  };
};
export const onChange = (dispatch, _ref3) => {
  let {
    form,
    order,
    travelerNumber
  } = _ref3;
  return (value, selectedTravelerId, shouldApplySelectionToAll) => {
    const isFirstTraveler = travelerNumber === 0;
    const applySelectionToAll = isFirstTraveler && shouldApplySelectionToAll;
    if (applySelectionToAll) {
      order.travelers.forEach(traveler => {
        if (traveler.ageType !== constants.ageTypes.INFANT) {
          setTravelerChoice({
            dispatch,
            form,
            order,
            travelerId: traveler.id || '',
            checkboxValue: value
          });
        }
      });
    } else {
      dispatch(changeFormValue(form, `${productName}.shouldApplySelectionToAll`, false));
      setTravelerChoice({
        dispatch,
        form,
        order,
        travelerId: selectedTravelerId,
        checkboxValue: value
      });
    }
  };
};
const mapStateToProps = (state, _ref4) => {
  var _formValueSelector;
  let {
    form
  } = _ref4;
  const shouldApplySelectionToAll = formValueSelector(form)(state, `${productName}.shouldApplySelectionToAll`);
  const firstTravelerSelectionValue = (_formValueSelector = formValueSelector(form)(state, `${productName}.${productName}-traveler-0`)) === null || _formValueSelector === void 0 ? void 0 : _formValueSelector.value;
  return {
    shouldApplySelectionToAll: shouldApplySelectionToAll !== null && shouldApplySelectionToAll !== void 0 ? shouldApplySelectionToAll : true,
    firstTravelerSelectionValue,
    totalBaggageQuantity: getNumberOfUnits(productName, form)(state),
    totalPrice: getPrice(productName, form)(state)
  };
};
const mapDispatchToProps = (dispatch, _ref5) => {
  let {
    form,
    order,
    travelerNumber
  } = _ref5;
  return {
    onChangeAll: onChangeAll(dispatch, {
      form,
      order
    }),
    onChange: onChange(dispatch, {
      form,
      order,
      travelerNumber
    })
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CabinBaggagePrimaryFlow);