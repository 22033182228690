import { Suspense, lazy } from 'react';
import { Inline, SiteFooter, Stack, Heading, vars, Columns, Column, breakpoints } from '@etg/wings';
import { BrandLogo, DangerousHtmlContent, PartnerLogo } from '@eti/components';
import { useTranslation, useSiteContext, useProperty } from '@eti/providers';
import { css } from '@eti/styles';
import { constants, isPartner } from '@eti/utils';
import Media from 'react-media';
import { useLocation } from 'react-router-dom';
import { type MainMenuItem } from '@eti/schema-types';
import LanguageSelectionDropdown from '../../common/localization/components/LanguageSelectionDropdown';
import SettingsGraphqlContainer from '../../common/settings/components/SettingsGraphqlContainer';
import { routes } from '../../constants/routesAndApisConstants';
import { getPartnerSpecificStylesFor } from '../utils/footerUtils';
import MyBookingsButton from '../../common/authentication/components/MyBookingsButton';
import { useLanguage } from '../../common/localization/custom-hooks/language';
import Blindado from './Blindado';
import ToggleableFooterMenu from './ToggleableFooterMenu';

const SkytraxSeal = lazy(() => import('./SkytraxSeal'));

const { ADR, TRIPSTACK } = constants.brands;

const legalStyles = css`
  font-size: 0.625rem;
  text-align: center;

  @media (min-width: ${breakpoints._768}) {
    font-size: 0.75rem;
  }
`;

const poweredByStyles = css`
  height: 40px;
  width: auto;
`;

const headingStyles = css`
  display: flex;
  margin-top: 0.75rem;
  width: 100%;

  @media (min-width: ${breakpoints._970}) {
    margin: 0;
  }
`;

const linkStyles = css`
  & a {
    color: ${vars.colors.footer.text};
    font-size: 0.875rem;
  }
`;

const languageDropdownStyles = css`
  max-width: none;

  @media (min-width: ${breakpoints._768}) {
    max-width: 356px;
  }
`;

const myBookingsButtonStyles = css`
  align-self: stretch;
`;

const stackStyles = css`
  @media (max-width: ${breakpoints._768}) {
    width: 100%;
  }
`;

type IdAndUrlType = { id: string; url: { href: string } }[];

export interface FooterProps {
  brand: string;
  legal: string;
  menus: { mainMenuItems: MainMenuItem[] }[];
  paymentProviders: IdAndUrlType;
  trustedPartners: IdAndUrlType;
}

const Footer = ({ brand, legal, paymentProviders, trustedPartners, menus = [] }: FooterProps) => {
  const { t } = useTranslation();
  const { p } = useProperty();
  const { availableLanguages } = useSiteContext();
  const { lessThanTwoAvailableLanguages } = useLanguage();
  const { pathname } = useLocation();
  const isCFCPage = pathname.includes(routes.CARRIER_FLIGHT_CONFIRMATION);
  const showMyBookingsButton = p('IbeClient.MyBookings.Header.Button.Enabled');

  const Titles: string[] = [
    t('Footer.ProductsAndServices.Text'),
    t('Footer.Company.Text'),
    t('Footer.Help.Text'),
  ];
  const isFooterMenuEnabled: boolean = p('IbeClient.FooterMenu.Enabled') && !isCFCPage;
  const isBlindadoEnabled = p('IbeClient.Blindado.Enabled');
  const isSettingsEnabled =
    p('IbeClient.Settings.Selection.Country.Enabled') ||
    p('IbeClient.Settings.Selection.Language.Enabled') ||
    p('IbeClient.Settings.Selection.Currency.Enabled');
  const shouldShowMultiLanguage = p('Site.MultiLanguage.Enabled') && !lessThanTwoAvailableLanguages;

  return (
    <SiteFooter data-testid="siteFooter">
      <Stack spacing={48}>
        {isFooterMenuEnabled && (
          <Columns
            className={linkStyles}
            collapseBelow={breakpoints._970}
            data-testid="footer-navigationMenu-container"
            spacing={{ [breakpoints._0]: 0, [breakpoints._970]: 24 }}
          >
            {menus.map((menu, index) => (
              <Column
                key={`menu-${index}`}
                width={{ [breakpoints._0]: '4/4', [breakpoints._970]: '1/4' }}
              >
                <Media query={`(min-width: ${breakpoints._970})`}>
                  {(matches) =>
                    !matches ? (
                      <ToggleableFooterMenu
                        data-testid={Titles[index]}
                        menu={menu.mainMenuItems}
                        title={Titles[index]}
                      />
                    ) : (
                      <Stack>
                        <Heading as="h2" level={4}>
                          {Titles[index]}
                        </Heading>
                        <Stack as="ul" spacing={16}>
                          {menu.mainMenuItems.map((mainMenuItem) => (
                            <li key={`linkbutton-${mainMenuItem.name}`}>
                              <a href={mainMenuItem.path as string}>{mainMenuItem.name}</a>
                            </li>
                          ))}
                        </Stack>
                      </Stack>
                    )
                  }
                </Media>
              </Column>
            ))}
            <Column>
              <Stack>
                <Heading
                  as="h2"
                  className={headingStyles}
                  data-testid="login-and-language-header"
                  level={4}
                >
                  {availableLanguages.length < 2
                    ? t('Footer.Login.Text')
                    : t('Footer.LoginAndLanguage.Text')}
                </Heading>
                <Stack align="start">
                  <Stack align="center" className={stackStyles}>
                    {showMyBookingsButton && (
                      <div className={myBookingsButtonStyles}>
                        <MyBookingsButton showOnlyLargeButton />
                      </div>
                    )}

                    {brand === ADR && (
                      <Suspense fallback="">
                        <SkytraxSeal />
                      </Suspense>
                    )}
                  </Stack>
                </Stack>
                {isSettingsEnabled ? (
                  <SettingsGraphqlContainer componentType="Footer" />
                ) : (
                  shouldShowMultiLanguage && (
                    <LanguageSelectionDropdown
                      isShownInFooter
                      languageDropdownStyles={languageDropdownStyles}
                    />
                  )
                )}
                {isBlindadoEnabled && <Blindado />}
              </Stack>
            </Column>
          </Columns>
        )}
        <Stack>
          {isPartner(brand) && brand !== TRIPSTACK && (
            <SiteFooter.PoweredBy>
              <Inline align="center">
                <BrandLogo brand="poweredbygotogate" className={poweredByStyles} />
              </Inline>
            </SiteFooter.PoweredBy>
          )}
          {paymentProviders && paymentProviders.length > 0 && (
            <SiteFooter.PaymentProviders data-testid="siteFooter-paymentProviders-wrapper">
              <Inline
                align="center"
                alignY="center"
                aria-label={t('Footer.PaymentProviders.Text')}
                as="ul"
                spacing={24}
              >
                {paymentProviders.map(({ id, url }) => (
                  <PartnerLogo
                    className={getPartnerSpecificStylesFor(id)}
                    collection="payment-providers"
                    href={url?.href}
                    id={id}
                    key={id}
                  />
                ))}
              </Inline>
            </SiteFooter.PaymentProviders>
          )}
          {trustedPartners && trustedPartners.length > 0 && (
            <SiteFooter.TrustedPartners data-testid="siteFooter-trustedPartners-wrapper">
              <Inline
                align="center"
                alignY="center"
                aria-label={t('Footer.TrustedPartners.Text')}
                as="ul"
                spacing={24}
              >
                {trustedPartners.map(({ id, url }) => (
                  <PartnerLogo
                    className={getPartnerSpecificStylesFor(id)}
                    collection="trusted-partners"
                    href={url?.href}
                    id={id}
                    key={id}
                  />
                ))}
              </Inline>
            </SiteFooter.TrustedPartners>
          )}
          <Inline align="center">
            <DangerousHtmlContent className={legalStyles} content={legal} />
          </Inline>
        </Stack>
      </Stack>
    </SiteFooter>
  );
};

export default Footer;
