import type { AlignTypes } from '@etg/wings';
import { Stack, breakpoints } from '@etg/wings';
import { css } from '@eti/styles';
import type { ReactNode } from 'react';

interface FormWrapperProps {
  children: ReactNode;
  isMultiStop: boolean;
  onSubmit: () => {};
}

interface StyledStackProps {
  align: AlignTypes;
  children: ReactNode;
}

const formWrapperStyles = (isMultiStop: boolean) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${breakpoints._768}) {
    flex-direction: ${isMultiStop ? 'column' : 'row'};
  }
`;

const stackStyles = css`
  flex: 3;
`;

export const FormWrapper = ({ children, isMultiStop, onSubmit }: FormWrapperProps) => (
  <form className={formWrapperStyles(isMultiStop)} onSubmit={onSubmit}>
    {children}
  </form>
);

export const StyledStack = ({ children, ...rest }: StyledStackProps) => (
  <Stack className={stackStyles} {...rest}>
    {children}
  </Stack>
);

export const extendedSearchFormStyles = css`
  padding-block-start: 24px;
`;

export const linkButtonStyles = css`
  display: flex;
`;

export const iconStyles = (isExpanded: boolean) => css`
  margin-inline-start: 8px;
  transform: rotateX(${isExpanded ? '180deg' : '0deg'});
  transition: transform 0.15s ease-in-out;
`;

export const motionVariantsForDatesAndPassengers = {
  visible: {
    display: 'block',
    opacity: 1,
    top: 0,
    height: 'auto',
    transition: {
      type: 'tween',
      duration: 0.2,
    },
  },
  hidden: {
    opacity: 0,
    top: 700,
    height: 0,
    transition: {
      type: 'tween',
      duration: 0.3,
    },
    transitionEnd: { display: 'none' },
  },
};

export const motionVariantsExtendedOptions = {
  show: {
    height: 'auto',
    opacity: 1,
    transition: {
      type: 'tween',
      duration: 0.3,
      opacity: { delay: 0.3 },
    },
    transitionEnd: { overflow: 'visible' },
  },
  hide: {
    height: 0,
    opacity: 0,
    marginBottom: 0,
    transition: {
      type: 'tween',
      duration: 0.3,
      height: { delay: 0.3 },
      marginBottom: { delay: 0.3 },
    },
    transitionEnd: { overflow: 'hidden' },
  },
};
